<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.step')"
      filterable
      clearable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(step, index) in steps"
        :key="'steps-' + index"
        :label="step.name"
        :value="step.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters({
      steps: "attributes/steps",
    }),
  },
  methods: {
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
